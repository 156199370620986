/**
 * 파일명 : Pagination.jsx
 * 설명 : 사업실적, 회사소개 게시판 페이징
 * 수정내역
 ************************************************
 * Version    수정일자      수정자    수정내용      
 ************************************************
 * 1.0       2023-03-28   황은택     최초작성
 * 
 */

/************************************************
 * Import 영역  
 ************************************************/
import "../css/pagination.css";
import { Button } from 'antd';
import { DoubleLeftOutlined, LeftOutlined, RightOutlined, DoubleRightOutlined } from '@ant-design/icons';

function Pagination({ limit,
    page,
    setPage,
    blockNum,
    setBlockNum,
    counts,
    pageLimit }) {
    
    const createArr = (n) => {
        const iArr = new Array(n);
        for (let i = 0; i < n; i++) iArr[i] = i + 1;
        return iArr;
    }; // 새로운 배열을 만들기 위한 함수

    // const pageLimit = 10; // 보여줄 페이지네이션 개수
    const totalPage = Math.ceil(counts / limit);
    const blockArea = Number(blockNum * pageLimit); // 화면 전환 할 때 보여줄 페이지네이션 개수를 구역으로 c지정한다.
    const nArr = createArr(Number(totalPage)); // nArr 함수에 전체 페이지의 개수를 배열로 담는다.
    const pArr = nArr.slice(blockArea, Number(pageLimit) + blockArea); // 페이지네이션 구역을 nArr 함수에 slice하여 원하는 페이지네이션 block 만 보여 줄 수 있게 설정
    
    const firstPage = () => {
        setPage(1);
        setBlockNum(0);
      };
    
      const lastPage = () => {
        setPage(totalPage);
        setBlockNum(Math.ceil(totalPage / pageLimit) - 1);
      };
    
      const prevPage = () => {
        if (page <= 1) { 
          return; 
        } // page가 1보다 작거나 같으면 아무 것도 리턴하지 않는다.
        if (page - 1 <= pageLimit * blockNum) {
          setBlockNum((n) => n - 1);
        } // 현재 페이지 - 1 이 보여줄 페이지네이션 개수(pageLimit) * blockNum 보다 작거나 같으면 setBlockNum에 - 1 을 작동시킨다.
        setPage((n) => n - 1); // setPage를 현재 페이지에서 -1 로 이동시킨다.
      };
    
      const nextPage = () => {
        if (page >= totalPage) {
          return;
        } // page가 마지막 페이지보다 크거나 같으면 아무 것도 리턴하지 않는다.
        if (pageLimit * Number(blockNum + 1) < Number(page + 1)) {
          setBlockNum((n) => n + 1);
        } //보여줄 페이지네이션 개수(pageLimit) * (blockNum+1) 가 page + 1보다 작다면 setBlockNum은 현재 페이지 + 1을 한다.
        setPage((n) => n + 1); //setPage에 현재 페이지 + 1을 한다.
      };

    return (
        <>
            <Button
                type="text" shape="circle"
                onClick={() => {
                firstPage();
                }}
                disabled={page === 1}
            >
                <DoubleLeftOutlined />
            </Button>
            <Button
                type="text" shape="circle"
                onClick={() => {
                prevPage();
                }}
                disabled={page === 1}
            >
                <LeftOutlined />
            </Button>
            {pArr.map((n) => (
                <Button
                    className="pagingBtn" shape="circle" style={{border:"none"}}
                    key={n}
                    onClick={() => {
                    setPage(n);
                    }}
                    aria-current={page === n ? 'page' : undefined}
                >
                    {n}
                </Button>
            ))}
            <Button
                type="text" shape="circle"
                onClick={() => {
                nextPage();
                }}
                disabled={page === totalPage}
            >
                <RightOutlined />
            </Button>
            <Button
                type="text" shape="circle"
                onClick={() => {
                lastPage();
                }}
                disabled={page === totalPage}
            >
                <DoubleRightOutlined />
            </Button>
        </>
    );
}

export default Pagination;