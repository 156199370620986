/**
 * <p>문자열 날짜를 받아서 요일 숫자값 리턴</p>
 * @param sDate - 문자열 날짜
 * @return integer (0~6)
 */
export function fn_getImageUrl(strBase64){
    var b64toBlob = require('b64-to-blob'); 

    var contentType = 'image/png';
    var blob = b64toBlob(strBase64, contentType);
    
    var blobUrl = URL.createObjectURL(blob);
   
    // setTimeout(() => {
    //     URL.revokeObjectURL(blobUrl);
    // }, 500);

    return blobUrl;
}
