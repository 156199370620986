/**
 * 파일명 : DaumPostCode.jsx
 * 설명 : 다음 카카오 우편번호 주소검색 팝업
 * 수정내역
 ************************************************
 * Version    수정일자      수정자    수정내용      
 ************************************************
 * 1.0       2023-11-21     김용재     최초작성
 * 
 */

/************************************************
 * Import 영역  
 ************************************************/
import { Modal } from "antd";
import {useEffect, useState} from "react";
import DaumPostCode from "react-daum-postcode";

const DaumPost = ({onComplete}) => {

    const [isOpen, setIsOpen] = useState();

    useEffect(() => {
        setIsOpen(true);
    }, []);

    // 주소 선택 완료
    const handleComplete = (data) => {

        // 선택한 주소정보를 넘겨준다
        onComplete(data);
        // onclose();
        setIsOpen(false);
    };


    return(
        <Modal
            open={isOpen}
            onOk={handleComplete}
            onCancel={handleComplete}
        >
            <div style={{padding:'10px', height:'500px'}}>
                <DaumPostCode
                    style={{height:'100%'}}
                    onComplete={handleComplete}
                    autoClose
                    animation
                >
                </DaumPostCode>
            </div>
            
        </Modal>
        
    )
}
export default DaumPost;
