/**
 * 파일명 : TgDatePicker.jsx
 * 설명 : 공통 antd DatePicker
 * 수정내역
 ************************************************
 * Version    수정일자      수정자    수정내용      
 ************************************************
 * 1.0       2023-11-23    김용재     최초작성
 * 
 */

/************************************************
 * Import 영역  
 ************************************************/
import {useEffect, useState} from "react"
import { DatePicker } from 'antd';
import "../css/modal.css"; // css

/**
 * TgDatePicker : 공통 데이트피커 컴포넌트
 * 
 * @param {Object} props       - 부모 컴포넌트로부터 전달되는 속성
 *  @property {string} id        - 컴포넌트 ID
 *  @property {string} name      - 컴포넌트 이름
 *  @property {string} className - 컴포넌트의 css클래스
 *  @property {string} indexKey  - list 상태인 경우 배열의 인덱스 키(list.map 사용시 사용)
 *  @property {Object} params    - 컴포넌트에 필요한 Json 옵션
 *    @property {string}   params.picker      - DatePicker 날짜 팝업 형식{ '':기본, month:월, quarter:분기, year:년 }
 *    @property {string}   params.data_node   - 입력된 값이 저장될 state 필드명
 *    @property {function} params.useState    - 상태를 변경하는 함수
 *    @property {string}   params.listYn      - Y 또는 N, useState가 list인 경우(list.map 사용시 사용)
 *    @property {string}   params.placeholder - 컴포넌트 플레이스홀더
 *    @property {string}   params.width       - 컴포넌트 너비
 *    @property {string}   params.format      - 날짜 포맷 ex) 'YYYY-MM-DD', 'YYYY.MM.DD" 등 
 */
const TgDatePicker = (props) => {

    const { id, name, className, params, indexKey } = props;
    
    /************************************************
     * params 변수 선언 영역
     ************************************************/
    let strPicker      = '';
    let strFormat      = '';
    let strPlaceHolder = '';
    let strListYn      = '';

    if( params !== undefined ) {
        strPicker      = params.picker;
        strFormat      = params.format;
        strPlaceHolder = params.placeholder;
        strListYn      = params.listYn;
    }


    const [dateFormat, setDateFormat] = useState();



    // 날짜 변경 이벤트
    const datePikerOnChange = (_, strDate) => {
    
        // state가 list 형태이면 배열 index에 값 셋팅
        if(strListYn === 'Y'){            

            params.useState((prevState) => prevState.map((field, i) => i === indexKey ? {...field, [params.data_node] : strDate} : field));

        } else {            

            params.useState((prevState) => ({
                ...prevState,
                [params.data_node] : strDate
            }))    

        }        

        setDateFormat(strFormat);
    }

    // 날짜popup 오픈 이벤트
    const dateOnOpenChange = (status) =>{
        /**
         * 사용자가 날짜값을 입력할때는 YYYYMMDD형식으로 입력되고 날짜 팝업이 * 닫히면 사용자 지정 Format으로 변경  
         */              
        // YYYY-MM-DD => YYYYMMDD, YYYY.MM.DD => YYYYMMDD로 변환되게 영문자만 추출
        const format = strFormat.replace(/[^a-zA-Z]/g, '');
        if(status === true){
            setDateFormat(format);
        } else {
            setDateFormat(strFormat);
        }
    }

    return (
        <DatePicker 
            id={id}
            name={name}
            picker={strPicker}  
            className={className}
            placeholder={strPlaceHolder}
            allowClear={false}
            format={dateFormat}
            onChange={datePikerOnChange}
            onOpenChange={dateOnOpenChange}                        
        />   
    );
}
export default TgDatePicker;