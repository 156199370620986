import { Modal, message } from "antd";
import { useState } from "react";

/**
 * 정합성 체크       
 * @param {Object} inputs           - JsonObject
 * @param {string} inputs.id        - dom요소 id명
 * @param {string} inputs.data_nm   - dom요소 name명
 * @param {string} inputs.disply_nm - 에러시 message 요소텍스트
 * @param {string} inputs.req_yn    - 필수여부(true, false)
 * @param {string} inputs.combo_yn  - dom요소가 콤보박스여부(true, false)
 * @param {string} inputs.comboVal  - 콤보박스 시 비교할 코드명
 */
export function fn_validation(inputs) {
    var cop           = null;
    var copFocus      = null;
    var rtn_comp      = null;
    var rtnMsg        = null;
    var rtn           = true;
    var comboInnerTxt = null;

    inputs.forEach(function(element) {        

        cop   = document.getElementById(element.id);
        
        /* 필수입력 체크 */
        if(element.req_yn) {
            /* Select 요소 정합성 체크 */
            if(element.combo_yn){
                comboInnerTxt = document.getElementsByName(element.data_nm)[0].childNodes[0].innerText;

                // element.comboVal의 값이랑 같으면 retun false
                if(comboInnerTxt === element.comboVal) {
                    rtnMsg = rtnMsg === null ? element.disply_nm + "은(는) 필수선택 항목 입니다." : rtnMsg;
                    copFocus = copFocus === null ? cop : copFocus;
                    rtn = false;
                }

            } else {
                /* Input 요소 정합성 체크 */
                if(cop.value === '' || cop.value === null) {
                    rtnMsg = rtnMsg === null ? element.disply_nm + "은(는) 필수입력 항목 입니다." : rtnMsg;
                    rtn_comp = cop;
                    copFocus = copFocus === null ? cop : copFocus;
                    rtn = false;
                }
            }              
        }
    })

    if(rtnMsg) {
        message.error(rtnMsg);
        copFocus.focus();       
    }

    return rtn;
}

/**
 * 컨펌 모달       
 * @param {Object} params            - JsonObject
 * @param {string} params.title      - 모달 제목
 * @param {string} params.content    - 모달 내용
 * @param {string} params.okText     - 확인버튼 텍스트
 * @param {string} params.cancelText - 닫기버튼 텍스트
 * @param {function} callback        - 확인 또는 취소 버튼을 클릭할 때 호출되는 콜백 함수
 */
export function fn_modalConfirm(params, callback) {

    Modal.confirm({
        title        : params.title,
        content      : params.content,
        okText       : params.okText,
        cancelText   : params.cancelText,
        okButtonProps: {
            style:{
                background: "#6665fe"
            }
        },
        onOk() {
            callback(true)
        },
        onCancel() {
            callback(false)
        }                
    })             
}

/**
 * 파일명 확장자 Return
 * @param {String} fileName - 파일명
 * @return {String} 파일확장자 ex) .xlsx, hwp .. 
 */
export function fn_getExtension(fileName) {

    let strFileNm = fileName;
    let lastIndex = strFileNm.lastIndexOf(".");

    if(lastIndex < 0 ){
        return "";
    }
    
    // 파일명에서 .뒤 확장자를 추출
    let strFileExtension = strFileNm.substring(lastIndex+1).toLowerCase();
    return strFileExtension;
}