/**
 * 파일명 : hom0303m.jsx
 * 설명 : GALLERY
 * 수정내역
 ************************************************
 * Version    수정일자      수정자    수정내용      
 ************************************************
 * 1.0       2023-03-28   황은택     최초작성
 *           bordController GALLERY 목록 정보 조회 
 */

/************************************************
 * Import 영역  
 ************************************************/
import { useState, useEffect } from 'react';
import "../../css/body.css";  // css
import { Input } from 'antd';
// import {HomeOutlined} from '@ant-design/icons';
import CompanyBoard from "../../components/CompanyBoard.jsx";     // Board MAP
import Pagination from "../../components/Pagination.jsx";  // Pagination

export default function Hom0303m() {

    // 갤러리 목록 조회
    const [companyBoardInfo, setCompanyBoardInfo] = useState([]);
    // useEffect(함수, 배열) : 컴포넌트가 화면에 나타났을 때 자동 실행
    useEffect(() => {
        // fetch(url, options) : Http 요청 함수
        fetch("/selectBoard"
        , {
            method: "POST"
          , headers: {'Content-type': 'application/json'}
          , body: JSON.stringify({
                                    instCd: '001'
                                  , boardDcd: '6'
                                  , condition: ''
                                })
        })
        .then((response) => response.json())
        .then(data => {setCompanyBoardInfo(data)});
    }, []);

    const [limit, setLimit] = useState(3);        // 한 페이지에 보여줄 데이터의 개수
    const [page, setPage] = useState(1);          // 페이지 초기 값은 1페이지
    const [blockNum, setBlockNum] = useState(0);  // 한 페이지에 보여 줄 페이지네이션의 개수를 block으로 지정하는 state. 초기 값은 0

    const offset = (page - 1) * limit;

    const { Search } = Input;
    function onSearch(value)
    {
        fetch("/selectBoard"
        , {
            method: "POST"
          , headers: {'Content-type': 'application/json'}
          , body: JSON.stringify({
                                    instCd: '001'
                                  , boardDcd: '6'
                                  , condition: value
                                })
        })
        .then((response) => response.json())
        .then(data => {setCompanyBoardInfo(data)});
    }

    return (
        <div>
            <div className="hom0303m">
                <div className="title">
                    {/* <div>
                        <HomeOutlined /> &gt; 홍보센터 &gt; <strong>갤러리</strong>
                    </div> */}
                    <h3>&nbsp;GALLERY</h3>
                </div>
                <div className="body">
                    <div className="contents">
                        <div className="board_top">
                            <p>총 <span>{companyBoardInfo.length}</span>건</p>
                            <div className="search">
                                <Search placeholder="input search text" onSearch={onSearch} style={{ width: "200px" }} />
                            </div>
                        </div>
                        <div className="board">
                            <table>
                                <tbody>
                                { companyBoardInfo && companyBoardInfo.slice(offset, offset + limit).map( companyBoard => <CompanyBoard key={companyBoard.id} CompanyBoard={companyBoard} page="/hom0304m" />) }
                                </tbody>
                            </table>
                        </div>
                        <div className="pagination">
                            <Pagination
                                limit={limit}
                                page={page}
                                setPage={setPage}
                                blockNum={blockNum}
                                setBlockNum={setBlockNum}
                                counts={companyBoardInfo.length}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}