// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n/* 화면 style */\n\n/* hom0001p */\n.Screen_hom0001m__Rzaaa li img {width: 100vw; margin-bottom: -4px;}\n\n/* hom0101m */\n.Screen_hom0101m__hWPCd dd b {background-color: rgb(227, 229, 250); font-weight: 900; font-style: normal;}\n.Screen_hom0101m__hWPCd dd i {font-size: 24px; color: #2061bd; font-weight: 900;}\n\n/* hom0401p */\n.Screen_hom0401p__Tisvf {padding: 20px 10px;}\n\n/* hom0402m */\n.Screen_hom0402m__xaPQH ul > li dt:has(p) {display: flex; flex-direction: column; align-items: start; gap: 10px; text-align: start;}\n.Screen_hom0402m__xaPQH ul > li dt p {font-size: 18px; font-weight: 600; }\n.Screen_hom0402m__xaPQH ul > li dt p b {color: #003b83; font-size: 22px; font-weight: 900}\n", "",{"version":3,"sources":["webpack://./src/css/Screen.module.css"],"names":[],"mappings":";AACA,aAAa;;AAEb,aAAa;AACb,gCAAkB,YAAY,EAAE,mBAAmB,CAAC;;AAEpD,aAAa;AACb,8BAAgB,oCAAoC,EAAE,gBAAgB,EAAE,kBAAkB,CAAC;AAC3F,8BAAgB,eAAe,EAAE,cAAc,EAAE,gBAAgB,CAAC;;AAElE,aAAa;AACb,yBAAW,kBAAkB,CAAC;;AAE9B,aAAa;AACb,2CAA6B,aAAa,EAAE,sBAAsB,EAAE,kBAAkB,EAAE,SAAS,EAAE,iBAAiB,CAAC;AACrH,sCAAwB,eAAe,EAAE,gBAAgB,EAAE;AAC3D,wCAA0B,cAAc,EAAE,eAAe,EAAE,gBAAgB","sourcesContent":["\n/* 화면 style */\n\n/* hom0001p */\n.hom0001m li img {width: 100vw; margin-bottom: -4px;}\n\n/* hom0101m */\n.hom0101m dd b {background-color: rgb(227, 229, 250); font-weight: 900; font-style: normal;}\n.hom0101m dd i {font-size: 24px; color: #2061bd; font-weight: 900;}\n\n/* hom0401p */\n.hom0401p {padding: 20px 10px;}\n\n/* hom0402m */\n.hom0402m ul > li dt:has(p) {display: flex; flex-direction: column; align-items: start; gap: 10px; text-align: start;}\n.hom0402m ul > li dt p {font-size: 18px; font-weight: 600; }\n.hom0402m ul > li dt p b {color: #003b83; font-size: 22px; font-weight: 900}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hom0001m": "Screen_hom0001m__Rzaaa",
	"hom0101m": "Screen_hom0101m__hWPCd",
	"hom0401p": "Screen_hom0401p__Tisvf",
	"hom0402m": "Screen_hom0402m__xaPQH"
};
export default ___CSS_LOADER_EXPORT___;
