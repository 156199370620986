/**
 * 파일명 : hom0001p.jsx
 * 설명 : HEADER MENU
 * 수정내역
 ************************************************
 * Version    수정일자      수정자    수정내용      
 ************************************************
 * 1.0       2023-05-23   기라성     최초작성
 *     
 */

/************************************************
 * Import 영역  
 ************************************************/
import { Modal, Typography } from 'antd';
import together_logo_1 from "../../images/together_ci/together_logo_1.png";

export default function Hom0001p({isOpen, onClose}) {

    return (
        <Modal
         open={isOpen}
         title={null}
         footer={null}
         closable={true}
         width={735}
         onCancel={onClose}
        >
       
        <img src={together_logo_1} style={{width: '30%'}}/>
        <Typography style={{textAlign : 'center', fontFamily : 'Nanum Gothic', fontWeight : 'bold', fontSize: '25px'}}>합병에 따른 채권자 이의제출 및 구주권제출 공고 </Typography>
        <br/>
        <p style={{fontSize : '20px'}}>
        　주식회사 위드정보(이하 “위드정보”라 함)와 주식회사 투게더솔루션(이하 “투게더<br/>
        솔루션”이라 함)은 2024년 5 월 28 일자 개최한 임시주주총회에서 합병을 결의하고, <br/>
        그 결과로 위드정보는 투게더솔루션을 합병하여 그 권리의무 일체를 승계하고 위드<br/>
        정보는 존속하며 투게더솔루션은 해산하기로 결의하였습니다. <br/><br/>
        1. 합병개요 : 본 합병으로 위드정보는 존속하고 투게더솔루션은 해산하여 소멸함<br/>
        2. 합병기일 : 2024년 7월 1일<br/>
        3. 합병비율 : 위드정보와 투게더솔루션의 합병비율은 1 : 0.5로 함<br/> 
        4. 합병으로 인하여 발행하는 신주의 종류와 수 : 위드정보는 보통주식  32,600주를<br/>  
        　　　　　　발행하여, 합병기일에 투게더솔루션 주주에 대하여 합병신주를 할당<br/>
        　　　　　　교부함 <br/>
        <br/>
        　본 합병에 대하여 이의가 있는 채권자는 이 공고일 익일부터 1월 이내에 관계회사 
        에 이의를 제출하여 주시기 바라며, 또한 주주제위께서도 주권을 제출하시기 바라와   
        이에 공고합니다. 
        </p>
        <br/><br/>
        <p style={{fontSize : '20px', textAlign : 'center'}}>
        2024년  5 월 28 일<br/><br/>
        <font style={{fontSize : '20px', textAlign : 'center', fontWeight : 'bold'}}>
        주식회사 투게더솔루션    
        </font><br/>
        서울특별시 영등포구 양평로22길 21, 805호<br/> 
        (양평동5가, 선유도코오롱디지털타워)<br/>
        대표이사   최 희 억
        </p>
        </Modal>
    )
}