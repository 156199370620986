/**
 * 파일명 : hom0401p.jsx
 * 설명 : 채용정보
 * 수정내역
 ************************************************
 * Version    수정일자      수정자    수정내용
 ************************************************
 * 1.0       2023-11-21     김용재    최초작성
 * 1.1       2024-01-18     박경수      수정
 */

/************************************************
 * Import 영역
 ************************************************/
import { Button, Input, Modal, message, Upload } from 'antd';
import { useState, useRef, useEffect } from 'react';
import "../../css/modal.css"; // css
import commonCss from "../../css/Common.module.css"; // css
import screenCss from "../../css/Screen.module.css"; // css
import TextArea from 'antd/es/input/TextArea';
import DaumPost from '../../components/DaumPost';
import TgCombo from '../../components/TgCombo';
import TgDatePicker from '../../components/TgDatePicker'
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import * as common from '../../libs/common';

export default function Hom0401p({ isOpen, onClose }) {

    const { Search } = Input;
    const [isDaumPost, setIsDaumPost] = useState();

    const initial_state = {
        id: '',          // id
        instCd: '001',          // 기관코드(001:투게더솔루션)
        jobPostNo: '',          // 공고번호
        applNm: '',          // 지원자명
        gndrTpCd: '',          // 성별구분코드
        applBirdDt: '',          // 지원자생년월일
        applMobileNo1: '',          // 지원자전화번호1
        applMobileNo2: '',          // 지원자전화번호2
        applMobileNo3: '',          // 지원자전화번호3
        applEmail: '',          // 지원자이메일
        applPostNo: '',          // 우편번호
        applAddr: '',          // 지원자주소
        applDtlAddr: '',          // 지원자상세주소
        applDcd: '',          // 지원자구분코드(01:신입, 02:경력)
        eduTpCd: '',          // 학력구분코드
        schNm: '',          // 학교명
        entcYm: '',          // 입학년월
        gradYm: '',          // 졸업년월
        majorNm: '',          // 전공명
        selfIntroduction: '',          // 자기소개
        applRsn: '',          // 지원사유
        applMethDnm: '',          // 지원방법구분명
        rgstDtm: '',          // 등록일시
        emailDomainCd: '',          // 이메일도메인코드
        emailDomainCdNm: '',          // 이메일도메인코드명
    };

    /* 입사지원자기본 */
    const [applBase, setApplBase] = useState(initial_state);

    const [applCareer, setApplCareer] = useState([]);    // 경력사항
    const [applLicense, setApplLicense] = useState([]);    // 자격증
    const [emailDisable, setEmailDisable] = useState(false); // 이메일도메인 활성/비활성 상태
    const [prevImgSrc, setPrevImgSrc] = useState("");    // 증명사진미리보기
    const [prevImg, setPrevImg] = useState(null);  // 증명사진obj
    const photoInput = useRef();                             // 증명사진Ref
    const [fileList, setFileList] = useState([]);    // 첨부파일List

    /**
     * TgCombo
     */
    // 지원자구분(신입, 경력)
    const applDcdParams = {
        cd_grp_id: '034',
        data_node: 'applDcd',
        useState: setApplBase,
        placeholder: '(*)지원구분'
    }

    // 성별구분콤보박스
    const gndrTpCdParams = {
        cd_grp_id: '021',
        data_node: 'gndrTpCd',
        useState: setApplBase,
        placeholder: '(*)성별',
    }

    // 학력구분콤보박스
    let eduTpCdParams = {
        cd_grp_id: '022',
        data_node: 'eduTpCd',
        useState: setApplBase,
        placeholder: '선택',
    }

    // 이메일도메인 콤보
    const emailCdParams = {
        cd_grp_id: '',
        data_node: 'emailDomainCd',
        data_CodeNm: 'emailDomainCdNm',
        useState: setApplBase,
        placeholder: '직접작성',
        width: '200px',
        maxHeight: 100,
        all_cd: '',
        all_nm: '직접작성',
        // 직접코드입력 ex) [{cd:value, cdNm:name}, {cd:value, cdNm:name}]
        directCode: [{ cd: '01', cdNm: 'naver.com' }, { cd: '02', cdNm: 'google.com' }, { cd: '03', cdNm: 'daum.com' }],
    }

    // 재직여부 콤보
    const currEmpYnParams = {
        cd_grp_id: '',
        data_node: 'currEmpYn',
        useState: setApplCareer,
        listYn: 'Y',            // state가 List인 경우
        placeholder: '재직여부',
        // 직접코드입력 [{cd:value, cdNm:name}, {cd:value, cdNm:name}]
        directCode: [{ cd: 'Y', cdNm: '재직중' }, { cd: 'N', cdNm: '퇴사' }],
    }

    /**
     * TgDatePicker
     */
    // 생년월일
    const applBirdDtParams = {
        picker: '',// 날짜팝업형식 { 공백:기본, month:월, quarter:분기, year:년 }
        data_node: 'applBirdDt',
        useState: setApplBase,
        placeholder: '(*)생년월일',
        format: 'YYYY-MM-DD',
    }

    // 입학년월
    const entcYmParams = {
        picker: 'month', // 날짜팝업형식 { 공백:기본, month:월, quarter:분기, year:년 }
        data_node: 'entcYm',
        useState: setApplBase,
        placeholder: '입학년월',
        format: 'YYYY-MM',
    }

    // 졸업년월
    const gradYmParams = {
        picker: 'month', // 날짜팝업형식 { 공백:기본, month:월, quarter:분기, year:년 }
        data_node: 'gradYm',
        useState: setApplBase,
        placeholder: '졸업년월',
        format: 'YYYY-MM',
    }

    // 입사년월
    const careerStYmParams = {
        picker: 'month', // 날짜팝업형식 { 공백:기본, month:월, quarter:분기, year:년 }
        data_node: 'careerStYm',
        useState: setApplCareer,
        listYn: 'Y',            // state가 List인 경우
        placeholder: '입사년월',
        format: 'YYYY-MM',
    }

    // 퇴사년월
    const careerEdYmParams = {
        picker: 'month', // 날짜팝업형식 { 공백:기본, month:월, quarter:분기, year:년 }
        data_node: 'careerEdYm',
        useState: setApplCareer,
        listYn: 'Y',            // state가 List인 경우
        placeholder: '퇴사년월',
        format: 'YYYY-MM',
    }

    // 자격증취득일자
    const acqDtmParams = {
        picker: '', // 날짜팝업형식 { 공백:기본, month:월, quarter:분기, year:년 }
        data_node: 'acqDtm',
        useState: setApplLicense,
        listYn: 'Y',            // state가 List인 경우
        placeholder: '취득일자',
        format: 'YYYY-MM-DD',
    }

    /**
     * inputOnChange 핸들러
     */
    const inputOnChange = (e) => {

        const { name, value } = e.currentTarget;
        switch (name) {
            /* 이메일, 이메일도메인은 영문/숫자만 입력가능 */
            case 'applEmail':
            case 'emailDomainCdNm':
                setApplBase({ ...applBase, [name]: value.replace(/[^a-zA-Z0-9.]/g, '') });
                break;

            /* 연락처 숫자 입력가능  */
            case 'applMobileNo1':
            case 'applMobileNo2':
            case 'applMobileNo3':
                setApplBase({ ...applBase, [name]: value.replace(/[^0-9]/g, '') });
                break;
            default:
                setApplBase({ ...applBase, [name]: value });
                break;
        }
    }

    /**
     * applCareerOnChange 핸들러
     */
    const applCareerOnChange = (e, indexKey) => {

        const { name, value } = e.currentTarget;
        setApplCareer((applCareer) => (
            applCareer.map((field, i) => (
                i === indexKey ? { ...field, [name]: value } : field
            ))
        ))
    }

    /**
     * applLicenseOnChange 핸들러
     */
    const applLicenseOnChange = (e, indexKey) => {

        const { name, value } = e.currentTarget;
        setApplLicense((applLicense) => (
            applLicense.map((field, i) => (
                i === indexKey ? { ...field, [name]: value } : field
            ))
        ))
    }


    /**
     * Button On Click 핸들러
     */
    const btnOnClick = (e, indexKey) => {

        const name = e.currentTarget.name;
        let params = null;

        switch (name) {
            case 'btnSave': // 제출버튼 클릭
                saveApplicant();
                break;
            case 'addCareer': // 경력사항 추가
                const applCareerAdd = {
                    currEmpYn: '', // 재직여부
                    careerStYm: '', // 입사년월
                    careerEdYm: '', // 퇴직년월
                    corpNm: '', // 회사명
                    deptNm: '', // 근무부서
                    postNm: '', // 직급/직책
                    bizCntnt: '', // 업무내용
                    careerEdYmHidden: '', // 퇴직년월 숨김

                }
                setApplCareer(() => [...applCareer, applCareerAdd]);
                break;

            case 'addLicense':
                const applLicenseAdd = {
                    liceNm: '', // 자격증명
                    issNm: '', // 발급처명
                    acqDtm: '', // 취득일자
                }
                setApplLicense(() => [...applLicense, applLicenseAdd])
                break;

            case 'btnRemoveCareer': // 경력사항 삭제
                params = { title: '확인 메시지', content: '해당 경력을 삭제 하시겠습니까?', okText: '확인', cancelText: '취소' }
                common.fn_modalConfirm(params, (result) => {

                    if (!result) {
                        return;
                    }

                    const copyCareer = [...applCareer];
                    copyCareer.splice(indexKey, 1);
                    setApplCareer(copyCareer);
                });
                break;

            case 'btnRemoveLicense':
                params = { title: '확인 메시지', content: '해당 자격증을 삭제 하시겠습니까?', okText: '확인', cancelText: '취소' }
                common.fn_modalConfirm(params, (result) => {

                    if (!result) {
                        return;
                    }

                    const copyLice = [...applLicense];
                    copyLice.splice(indexKey, 1);
                    setApplLicense(copyLice);
                });
                break;

            case 'btnDelFile': // 파일삭제
                params = { title: '확인 메시지', content: '해당 파일을 삭제 하시겠습니까?', okText: '확인', cancelText: '취소' }
                common.fn_modalConfirm(params, (result) => {

                    if (!result) {
                        return;
                    }

                    const copyFile = [...fileList];
                    copyFile.splice(indexKey, 1);
                    setFileList(copyFile);
                });
                break;

        }
    }

    /**
     * emailDomain 선택 이벤트 : 이메일도메인콤보 [직접입력] 선택시 활성화
     */
    const emailDomainChange = (data) => {

        if (data.value === '') {
            setEmailDisable(false);
            setApplBase({ ...applBase, emailDomainCd: '', emailDomainCdNm: '' })

        } else {
            setEmailDisable(true);
        }
    }

    /**
     * currEmpOnChange 재직여부 선택 이벤트 : [재직중] 선택 시 퇴직년월 숨김
     */
    const currEmpOnChange = (data, _, indexKey) => {

        if (data.value === 'Y') {
            setApplCareer((applCareer) => applCareer.map((field, i) =>
                i === indexKey ? { ...field, careerEdYmHidden: 'hidden' } : field
            ))
        } else {
            setApplCareer((applCareer) => applCareer.map((field, i) =>
                i === indexKey ? { ...field, careerEdYmHidden: '' } : field
            ))
        }
    }

    // 우편번호 찾기 번튼 클릭(팝업Open)
    const addrSearch = () => {
        setIsDaumPost(true);
    }

    // 우편번호 선택 완료
    const handleComplete = (data) => {

        // 주소 셋팅
        setApplBase({
            ...applBase,
            applPostNo: data.zonecode, // 우편번호
            applAddr: data.address     // 주소
        });
        setIsDaumPost(false);
    }

    /**
     * 정합성 체크
     */
    const fn_CheckValidation = (tran_div) => {
        var params = [];

        if (tran_div === "save") {
            params = [
                { id: 'applNm', data_nm: 'applNm', disply_nm: '성명', req_yn: true, page: this },
                { id: 'applBirdDt', data_nm: 'applBirdDt', disply_nm: '생년월일', req_yn: true, page: this },
                { id: 'applEmail', data_nm: 'applEmail', disply_nm: '이메일', req_yn: true, page: this },
            ];

            // 이메일 도메인이 [직접작성]이면 정합성 체크
            if (applBase.emailDomainCd === null || applBase.emailDomainCd === '') {
                params.push({ id: 'emailDomainCdNm', data_nm: 'emailDomainCdNm', disply_nm: '이메일', req_yn: true, page: this });
            }

            params.push({ id: 'applMobileNo1', data_nm: 'applMobileNo1', disply_nm: '연락처', req_yn: true, page: this });
            params.push({ id: 'applMobileNo2', data_nm: 'applMobileNo2', disply_nm: '연락처', req_yn: true, page: this });
            params.push({ id: 'applMobileNo3', data_nm: 'applMobileNo3', disply_nm: '연락처', req_yn: true, page: this });

            // // 여기!! durl!!
            // // 학력 일자 체크
            // console.log(" eduTpCd : "  , applBase.eduTpCd);
            // console.log(" gradYm : "  , applBase.gradYm);
            // console.log(" entcYm : "  , applBase.entcYm);

            // if(applBase.eduTpCd !== null && applBase.eduTpCd !== ''){
            //     console.log(" eduTpCd : "  , applBase.eduTpCd);
            //     params.push({ id: 'schNm', data_nm: 'schNm', disply_nm: '학교명', req_yn: true, page: this });

            //     if (parseInt(applBase.entcYm) > parseInt(applBase.gradYm)) {
            //         console.log("???");
            //         params.push({ id: 'gradYm', data_nm: 'gradYm', disply_nm: '졸업일자', req_yn: true, page: this });
            //     }
            // }

            // 경력사항 정합성 체크
            if (applCareer.length > 0) {
                for (var i = 0; i < applCareer.length; i++) {
                    params.push({ id: 'corpNm' + i, data_nm: 'corpNm', disply_nm: '회사명', req_yn: true, page: this });
                    params.push({ id: 'careerStYm' + i, data_nm: 'careerStYm', disply_nm: '입사년월', req_yn: true, page: this });

                    // 경력사항 재직중일땐 퇴사년월 체크 안함                     
                    if (applCareer[i].currEmpYn !== 'Y') {
                        console.log(applCareer[i].currEmpYn);
                        params.push({ id: 'careerEdYm' + i, data_nm: 'careerEdYm', disply_nm: '퇴사년월', req_yn: true, page: this });
                    }

                    params.push({ id: 'deptNm' + i, data_nm: 'deptNm', disply_nm: '근무부서', req_yn: true, page: this });
                    params.push({ id: 'postNm' + i, data_nm: 'postNm', disply_nm: '직급/직책', req_yn: true, page: this });
                }
            }

            // 자격증 정합석 체크
            if (applLicense.length > 0) {
                for (var i = 0; i < applLicense.length; i++) {
                    params.push({ id: 'liceNm' + i, data_nm: 'liceNm', disply_nm: '자격증명', req_yn: true, page: this });
                    params.push({ id: 'lssNm' + i, data_nm: 'lssNm', disply_nm: '발급처명', req_yn: true, page: this });
                }
            }
        }

        return common.fn_validation(params);
    }

   

    /**
    * 입사지원서 제출
    */
    const saveApplicant = () => {
        /* 정합성 체크 */
        if (!fn_CheckValidation('save')) {
            return;
        }

        fetch("/saveApplicant", {
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify({
                ...applBase,
                applicantCareerDto: applCareer,
                applicantLicenseDto: applLicense,
            })
        })
            .then((response) => {

                if (!response.ok) {
                    throw Error(response.statusText);
                }

                return response.json();
            })
            .then((result) => {

                // 저장된 지원자 id 상태값 변경            
                setApplBase({ ...applBase, id: result.id });
                console.log(applBase);
                console.log(applCareer);
                console.log(applLicense);
                // 증명사진 혹은 첨부된 파일이 있으면 첨부파일 저장
                if (prevImg || fileList.length > 0) {
                    attachFileUpload(result);
                } else {
                    message.success("입사지원서 제출이 완료 되었습니다!!");
                }
            })
            .catch((error) => message.error(`입사지원서 제출 오류 : ${error}`))
    }

    /* 업로드 버튼 */
    const uploadButton = (
        <div className={commonCss.photo_cnt}>
            <PlusOutlined />
            <p>사진첨부</p>
        </div>
    );

    /* 증명사진 컴포넌트 */
    const PhotoView = () => {
        return (
            <div onClick={photoClick} className={commonCss.photo_fit}>
                {prevImgSrc ? (
                    <img src={prevImgSrc} alt="No Image" className={commonCss.photo_fit} />
                ) : (
                    uploadButton
                )}
                <input
                    type="file"
                    accept="image/*"
                    ref={photoInput}
                    onChange={uploadPhotoChange}
                    style={{ display: 'none' }}
                >
                </input>
            </div>
        )
    }

    const photoClick = () => {
        photoInput.current.click();
    }

    // 증명사진 업로드 FileReader 
    const uploadPhotoChange = () => {

        const photoFile = photoInput.current.files[0];
        if (photoFile === null || photoFile === undefined) {
            return;
        }

        const fileName = photoFile.name;

        // 증명사진 FileReader 
        const reader = new FileReader();
        reader.readAsDataURL(photoFile); // 파일URL
        reader.onloadend = () => {
            setPrevImgSrc(reader.result); // 증명사진 미리보기
            setPrevImg(photoFile);        // 증명사진 OBJ
        }
    }

    // 첨부파일 upload onChange 이벤트
    const uploadFileChange = (info) => {

        const file = info.file; // 선택된 파일

        const uploadLoadedFile = file.status === 'done' // 파일 업로드 완료

        // 파일 업로드 완료 시 수행
        if (uploadLoadedFile) {

            // fileList에 파일 추가
            const copyFileList = [...fileList];
            copyFileList.push(file);
            setFileList(copyFileList);
        }
    }

    // 첨부파일 저장
    const attachFileUpload = (result) => {

        const id = result.id; // 저장된 지원자 id

        // body에 담을 form 생성
        const formData = new FormData();

        // form에 파일 정보 셋팅 
        formData.append('applBaseId', id); // 지원자기본ID
        formData.append('files', prevImg) // 증명사진
        formData.append('filesDescription', '증명사진') // 증명사진Description

        // 첨부파일이 있으면 form에 파일정보 셋팅
        if (fileList) {
            fileList.forEach((file) => {
                formData.append('files', file.originFileObj); // 첨부파일
                formData.append('filesDescription', '첨부파일'); // 첨부파일Description
            });
        }

        // 첨부파일 저장
        fetch('/applicantUpload', {
            method: 'POST',
            body: formData,
        })
            .then((response) => {

                if (!response.ok) {
                    throw Error(response.statusText);
                }

                message.success("입사지원서 제출이 완료 되었습니다!!");
            })
            .catch((error) => message.error(`입사지원서 제출 오류 : ${error}`))
    }

    const fileProps = {
        showUploadList: false,
        customRequest: ({ onSuccess }) => {
            setTimeout(() => {
                onSuccess('ok');
            }, 0);
        },
        onChange: uploadFileChange,
    }
    return (
        <Modal
            open={isOpen}
            title={null} // 타이틀 숨김
            closable={false}
            footer={null} // 하단 버튼영역 숨김
            className={commonCss.popup_L}
        >
            <div className={screenCss.hom0401p}>
                <h1 className={commonCss.tit_01}>입사지원서</h1>
                <ul className={commonCss.main_align}>
                    {/* 증명사진 미리보기 */}
                    <li className={commonCss.photo_sec}>
                        <PhotoView />
                    </li>
                    {/* 입사지원자 기본정보 */}
                    <li>
                        <ul>
                            <li className={commonCss.w80p}>
                                <TgCombo id='applDcd' params={applDcdParams} className={commonCss.w120} />
                                <Input id='applNm' name='applNm' placeholder='(*)성명' value={applBase.applNm} onChange={inputOnChange} maxLength={100} className={commonCss.w250} title='(*)성명' />
                                <TgDatePicker id='applBirdDt' params={applBirdDtParams} className={commonCss.w120} title='(*)생년월일' />
                                <TgCombo id='gndrTpCd' params={gndrTpCdParams} className={commonCss.w120} title='(*)성별' />
                            </li>
                            <li className={commonCss.w80p}>
                                <Input id='applEmail' name='applEmail' placeholder='(*)이메일' value={applBase.applEmail} onChange={inputOnChange} maxLength={50} title='(*)이메일' />
                                <label>@</label>
                                <Input id='emailDomainCdNm' name='emailDomainCdNm' value={applBase.emailDomainCdNm} placeholder='(*)이메일주소' onChange={inputOnChange} disabled={emailDisable} maxLength={50} title='(*)이메일주소' />
                                <TgCombo params={emailCdParams} onChange={emailDomainChange} className={commonCss.w150} title='선택' />

                            </li>
                            <li>
                                <Input id='applMobileNo1' name='applMobileNo1' value={applBase.applMobileNo1} placeholder='(*)연락처' onChange={inputOnChange} maxLength={3} className={commonCss.w120} title='000(*)' />
                                <Input id='applMobileNo2' name='applMobileNo2' value={applBase.applMobileNo2} placeholder='(*)' onChange={inputOnChange} maxLength={4} className={commonCss.w150} title='0000(*)' />
                                <Input id='applMobileNo3' name='applMobileNo3' value={applBase.applMobileNo3} placeholder='(*)' onChange={inputOnChange} maxLength={4} className={commonCss.w150} title='0000(*)' />
                            </li>
                            <li>
                                <Search id='applPostNo' placeholder="우편번호" readOnly onSearch={addrSearch} value={applBase.applPostNo} enterButton className={commonCss.w200} title='우편번호' />
                                {/* 다음 카카오 우편번호 찾기 */}
                                {isDaumPost && (
                                    <DaumPost onComplete={handleComplete} />
                                )}
                                <Input readOnly value={applBase.applAddr} />
                            </li>
                            <li>
                                <Input name='applDtlAddr' placeholder='상세주소' value={applBase.applDtlAddr} onChange={inputOnChange} maxLength={500} title='상세주소' />
                            </li>
                        </ul>
                        <p className={commonCss.write_tip_ncs}>* (*)표시는 모두 필수입력사항입니다.</p>
                    </li>
                </ul>
                {/* 추가정보 */}
                <dl className={commonCss.sub_align}>
                    <dt>
                        <h3>최종학력</h3>
                    </dt>
                    <dd>
                        <ul className={commonCss.mb_10}>
                            <li className={commonCss.w100p}>
                                <TgCombo id='eduTpCd' params={eduTpCdParams} className={commonCss.w120} />
                                {/* 여기!! */}
                                <TgDatePicker id='entcYm' params={entcYmParams} className={commonCss.w120} />
                                <TgDatePicker id='gradYm' params={gradYmParams} className={commonCss.w120} />
                                <Input id='schNm' name='schNm' value={applBase.schNm} onChange={inputOnChange} placeholder='학교명' className={commonCss.w350} />
                                <Input id='majorNm' name='majorNm' value={applBase.majorNm} onChange={inputOnChange} placeholder='전공' className={commonCss.w350} />
                            </li>
                        </ul>
                        <p className={commonCss.write_tip_chs}>* 해당내용은 선택입력사항입니다.</p>
                    </dd>
                </dl>

                <dl className={commonCss.sub_align}>
                    <dt>
                        <h3>경력사항</h3>
                        {/* + button */}
                        <Button name='addCareer' onClick={btnOnClick} className={commonCss.btn_add_S} >
                            <PlusOutlined />
                        </Button>
                    </dt>
                    <dd>
                        {
                            applCareer.map((field, i) => {
                                return (
                                    <ul className={commonCss.add_info_02}>
                                        <li>
                                            <div className={commonCss.w100p}>
                                                <TgCombo id={'currEmpYn' + i} params={currEmpYnParams} indexKey={i} onChange={currEmpOnChange} className={commonCss.w120} />
                                                <TgDatePicker id={'careerStYm' + i} params={careerStYmParams} indexKey={i} className={commonCss.w120} />
                                                {field.careerEdYmHidden !== 'hidden' ? <TgDatePicker id={'careerEdYm' + i} params={careerEdYmParams} indexKey={i} className={commonCss.w120} /> : ''}
                                                <Input id={'corpNm' + i} name='corpNm' value={field.corpNm} placeholder='회사명' onChange={(e) => applCareerOnChange(e, i)} className={commonCss.w250} />
                                                <Input id={'deptNm' + i} name='deptNm' value={field.deptNm} placeholder='근무부서' onChange={(e) => applCareerOnChange(e, i)} className={commonCss.w120} />
                                                <Input id={'postNm' + i} name='postNm' value={field.postNm} placeholder='직급/직책' onChange={(e) => applCareerOnChange(e, i)} className={commonCss.w120} />

                                            </div>
                                            <Button id='button' name='btnRemoveCareer' onClick={(e) => btnOnClick(e, i)} className={commonCss.btn_minu_S} >
                                                <MinusOutlined />
                                            </Button>
                                        </li>
                                        <li className={commonCss.w80p}>

                                        </li>
                                        <li>
                                            <TextArea name="bizCntnt" value={field.bizCntnt} onChange={(e) => applCareerOnChange(e, i)} rows={2} maxLength={2000} showCount placeholder="업무내용" className={commonCss.cnt_box_S} />
                                        </li>
                                    </ul>

                                );
                            })
                        }
                        <p className={commonCss.write_tip_chs}>* 해당내용은 선택입력사항입니다.</p>
                    </dd>
                </dl>

                <dl className={commonCss.sub_align}>
                    <dt>
                        <h3>자격증</h3>
                        {/* + button */}
                        <Button name='addLicense' onClick={btnOnClick} className={commonCss.btn_add_S} >
                            <PlusOutlined />
                        </Button>
                    </dt>
                    <dd>
                        {
                            applLicense.map((field, i) => {
                                return (
                                    <ul className={commonCss.add_info_03}>
                                        <li>
                                            <div className={commonCss.w100p}>
                                                <Input id={'liceNm' + i} name='liceNm' value={field.liceNm} placeholder='자격증명' onChange={(e) => applLicenseOnChange(e, i)} className={commonCss.w480} />
                                                <Input id={'lssNm' + i} name='lssNm' value={field.lssNm} placeholder='발급처명' onChange={(e) => applLicenseOnChange(e, i)} className={commonCss.w250} />
                                                <TgDatePicker id={'acqDtm' + i} name='acqDtm' params={acqDtmParams} indexKey={i} className={commonCss.w120} />
                                            </div>
                                            <Button name='btnRemoveLicense' onClick={(e) => btnOnClick(e, i)} className={commonCss.btn_minu_S} >
                                                <MinusOutlined />
                                            </Button>
                                        </li>
                                    </ul>
                                );
                            })
                        }
                        <p className={commonCss.write_tip_chs}>* 해당내용은 선택입력사항입니다.</p>
                    </dd>
                </dl>
                <dl className={commonCss.sub_align}>
                    <dt>
                        <h3>자기소개</h3>
                    </dt>
                    <dd>
                        <TextArea
                            name="selfIntroduction"
                            placeholder="자기소개서를 작성해주세요."
                            onChange={inputOnChange}
                            value={applBase.selfIntroduction}
                            rows={2}
                            maxLength={2000}
                            showCount
                            autoSize={{
                                minRows: 6,
                                maxRows: 6,
                            }}
                            className={`${commonCss.cnt_box_S} ${commonCss.mb_30}`}
                        />
                        <p className={commonCss.write_tip_chs}>* 해당내용은 선택입력사항입니다.</p>
                    </dd>
                </dl>
                <dl className={commonCss.sub_align}>
                    <dt>
                        <h3>지원사유</h3>
                    </dt>
                    <dd>
                        <TextArea
                            name="applRsn"
                            placeholder="지원사유를 작성해주세요."
                            onChange={inputOnChange}
                            value={applBase.applRsn}
                            rows={2}
                            maxLength={2000}
                            showCount
                            autoSize={{
                                minRows: 6,
                                maxRows: 6,
                            }}
                            className={`${commonCss.cnt_box_S} ${commonCss.mb_30}`}
                        />
                        <p className={commonCss.write_tip_chs}>* 해당내용은 선택입력사항입니다.</p>
                    </dd>
                </dl>

                <dl className={commonCss.sub_align}>
                    <dt>
                        <h3>파일추가</h3>
                        <Upload {...fileProps}>
                            <Button name='addFiles' onClick={btnOnClick} className={commonCss.btn_add_S} >
                                <PlusOutlined />
                            </Button>
                        </Upload>
                    </dt>
                    <dd>
                        {
                            fileList.map((field, i) => {
                                return (
                                    <ul className={commonCss.add_info_03}>
                                        <li>
                                            <Input value={field.name} placeholder="선택된 파일이 없습니다." />
                                            <Button name='btnDelFile' onClick={(e) => btnOnClick(e, i)} className={commonCss.btn_minu_S} >
                                                <MinusOutlined />
                                            </Button>
                                        </li>
                                    </ul>
                                );
                            })
                        }
                        <p className={commonCss.write_tip_chs}>* 해당내용은 선택입력사항입니다.</p>
                    </dd>
                </dl>
                <div className={commonCss.btn_align}>
                    <Button className={commonCss.btn_save} name='btnSave' onClick={btnOnClick}>
                        제출
                    </Button>
                    <Button className={commonCss.btn_close} name='btnClose' onClick={onClose} >
                        닫기
                    </Button>
                </div>
            </div>
        </Modal>
    );
}