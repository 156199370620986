/**
 * 파일명 : kakao.jsx
 * 설명 : kakao map 
 * 수정내역
 ************************************************
 * Version    수정일자      수정자    수정내용      
 ************************************************
 * 1.0       2023-03-28   황은택     최초작성
 * 
 */

/************************************************
 * Import 영역  
 ************************************************/
import React, { useEffect } from 'react';

const MapKakao = ({meeting_place}) => {
    
    const { kakao } = window;

    useEffect(() => {
        const container = document.getElementById('map');
        const options = {
            center: new kakao.maps.LatLng(37.53983029535453, 126.89465476897927),
            level: 3
        };
        const map = new kakao.maps.Map(container, options);

        // 지도를 클릭한 위치에 표출할 마커입니다
        const marker = new kakao.maps.Marker({ 
            // 지도 중심좌표에 마커를 생성합니다 
            position: map.getCenter() 
        }); 
        // 지도에 마커를 표시합니다
        marker.setMap(map);

        // 지도에 클릭 이벤트를 등록합니다
        // 지도를 클릭하면 마지막 파라미터로 넘어온 함수를 호출합니다
        // kakao.maps.event.addListener(map, 'click', function(mouseEvent) {        
            
        //     // 클릭한 위도, 경도 정보를 가져옵니다 
        //     var latlng = mouseEvent.latLng; 
            
        //     // 마커 위치를 클릭한 위치로 옮깁니다
        //     marker.setPosition(latlng);
            
        //     var message = '클릭한 위치의 위도는 ' + latlng.getLat() + ' 이고, ';
        //     message += '경도는 ' + latlng.getLng() + ' 입니다';
            
        //     var resultDiv = document.getElementById('clickLatlng'); 
        //     resultDiv.innerHTML = message;
            
        // });

        

    }, [meeting_place]);

    // "Mobi" 가 User agent에 포함되어 있으면 모바일
    const isMobile = /Mobi/i.test(window.navigator.userAgent);

    return (
        <div>
            <div id="map" className={!isMobile ? "kakaoMap_W" : "kakaoMap_M"}></div>
        </div>
    );
}

export default MapKakao;
