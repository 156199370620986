/**
 * 파일명 : hom0101m.jsx
 * 설명 : 회사개요
 * 수정내역
 ************************************************
 * Version    수정일자      수정자    수정내용      
 ************************************************
 * 1.0       2023-03-28   황은택     최초작성
 *           businessController 호출 - 회사 정보 조회
 */

/************************************************
 * Import 영역  
 ************************************************/
import { useState, useEffect } from 'react';
import commonCss from '../../css/Common.module.css';
import screenCss from '../../css/Screen.module.css';

export default function Hom0101m() {

    // 회사정보 조회
    const [companyInfo, setCompanyInfo] = useState("");
    useEffect(() => {
        fetch("/selectCompany")
            .then((response) => response.json())
            .then(data => { setCompanyInfo(data) });
    }, []);

    // "Mobi" 가 User agent에 포함되어 있으면 모바일
    const isMobile = /Mobi/i.test(window.navigator.userAgent);

    return (
        <>
            {!isMobile ?
            // web
                <div className={screenCss.hom0101m}>
                    {/*  */}
                    <ul className={commonCss.scrn_header}>
                        <li>
                            <img className={commonCss.img_470} src={require("../../images/together_slogan.png")} alt="인사말" />
                        </li>
                        <li>
                            <dl>
                                <dt>
                                    <h3>투게더솔루션</h3>
                                    <h1>함께함의 가치를 더하다</h1>
                                </dt>
                                <dd>
                                    <p>
                                        안녕하십니까?
                                        <br /><br />
                                        변화를 두려워하지 않고,
                                        <br />
                                        날마다 새롭게 쉼없이 진보하고 있는 투게더솔루션 입니다.
                                        <br /><br />
                                        저희 홈페이지를 방문해 주신 여러분께 진심으로 감사드립니다.
                                        <br />
                                        투게더 솔루션은 고객과 같은 목표를 가지고
                                        <br />
                                        <b>최고의 금융 IT 서비스 제공</b>을 위하여 끊임없이 노력하고 있습니다.
                                        <br /><br />
                                        직원과 고객이 함께 함으로써 이룩한 성장을
                                        <br />
                                        소중히 여기는 기업이 될 수 있도록 노력하겠습니다.
                                        <br /><br />
                                        감사합니다.
                                    </p>
                                    <i>
                                        To gather our potential, We are TOGETHER !!
                                    </i>
                                </dd>
                            </dl>
                        </li>
                    </ul>
                    {/*  */}
                    <ul className={commonCss.scrn_body}>
                        <li>
                            <dl>
                                <dt>
                                    <h2>일반현황</h2>
                                </dt>
                                <dd>
                                    <table className={commonCss.tab_info}>
                                        <thead>
                                            <colgroup>
                                                <col style={{ width: "20%" }} />
                                                <col style={{ width: "40%" }} />
                                                <col style={{ width: "20%" }} />
                                                <col style={{ width: "auto" }} />
                                            </colgroup>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th>회사명</th>
                                                <td>(주)투게더솔루션</td>
                                                <th>대표자명</th>
                                                <td>최희억</td>
                                            </tr>
                                            <tr>
                                                <th>기술용역등록분야</th>
                                                <td colSpan={3}>업태 : 서비스 / 종목 : 소프트웨어 개발 및 유지보수</td>
                                            </tr>
                                            <tr>
                                                <th>주소</th>
                                                <td colSpan={3}>서울 영등포구 양평로 22길 21, 805호</td>
                                            </tr>
                                            <tr>
                                                <th>연락처</th>
                                                <td>전화번호 : 02-6959-1957</td>
                                                <td colSpan={2}>팩스 : 02-6959-1958</td>
                                            </tr>
                                            <tr>
                                                <th>회사설립년도</th>
                                                <td colSpan={3}>2019년 11월</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </dd>
                            </dl>
                        </li>
                    </ul>
                    {/*  */}
                    <ul className={commonCss.scrn_body}>
                        <li>
                            <dl>
                                <dt>
                                    <h2>조직도</h2>
                                </dt>
                                <dd>
                                    <img className={commonCss.img_1180} src={require("../../images/together_orgChart.png")} alt="조직도"></img>
                                </dd>
                            </dl>
                        </li>
                    </ul>
                    {/*  */}
                </div>
                :
                // mobile
                <div className={screenCss.hom0101m}>
                    {/*  */}
                    <ul className={commonCss.scrn_header}>
                        <li>
                            <img src={require("../../images/together_slogan.png")} alt="인사말" />
                        </li>
                        <li>
                            <dl>
                                <dt>
                                    <h3>투게더솔루션</h3>
                                    <h1>함께함의 가치를 더하다</h1>
                                </dt>
                                <dd>
                                    <p>
                                        안녕하십니까?
                                        <br /><br />
                                        변화를 두려워하지 않고,
                                        <br />
                                        날마다 새롭게 쉼없이 진보하고 있는 투게더솔루션 입니다.
                                        <br /><br />
                                        저희 홈페이지를 방문해 주신 여러분께 진심으로 감사드립니다.
                                        <br />
                                        투게더 솔루션은 고객과 같은 목표를 가지고
                                        <br />
                                        <b>최고의 금융 IT 서비스 제공</b>을 위하여 끊임없이 노력하고 있습니다.
                                        <br /><br />
                                        직원과 고객이 함께 함으로써 이룩한 성장을
                                        <br />
                                        소중히 여기는 기업이 될 수 있도록 노력하겠습니다.
                                        <br /><br />
                                        감사합니다.
                                    </p>
                                    <i>
                                        To gather our potential, We are TOGETHER !!
                                    </i>
                                </dd>
                            </dl>
                        </li>
                    </ul>
                    {/*  */}
                    <ul className={commonCss.scrn_body}>
                        <li>
                            <dl>
                                <dt>
                                    <h2>일반현황</h2>
                                </dt>
                                <dd>
                                    <table className={commonCss.tab_info}>
                                        <thead>
                                            <colgroup>
                                                <col style={{ width: "20%" }} />
                                                <col style={{ width: "40%" }} />
                                                <col style={{ width: "20%" }} />
                                                <col style={{ width: "auto" }} />
                                            </colgroup>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th>회사명</th>
                                                <td>(주)투게더솔루션</td>
                                                <th>대표자명</th>
                                                <td>최희억</td>
                                            </tr>
                                            <tr>
                                                <th>기술용역등록분야</th>
                                                <td colSpan={3}>업태 : 서비스 / 종목 : 소프트웨어 개발 및 유지보수</td>
                                            </tr>
                                            <tr>
                                                <th>주소</th>
                                                <td colSpan={3}>서울 영등포구 양평로 22길 21, 805호</td>
                                            </tr>
                                            <tr>
                                                <th>연락처</th>
                                                <td>전화번호 : 02-6959-1957</td>
                                                <td colSpan={2}>팩스 : 02-6959-1958</td>
                                            </tr>
                                            <tr>
                                                <th>회사설립년도</th>
                                                <td colSpan={3}>2019년 11월</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </dd>
                            </dl>
                        </li>
                    </ul>
                    {/*  */}
                    <ul className={commonCss.scrn_body}>
                        <li>
                            <dl>
                                <dt>
                                    <h2>조직도</h2>
                                </dt>
                                <dd>
                                    <img src={require("../../images/together_orgChart.png")} alt="조직도"></img>
                                </dd>
                            </dl>
                        </li>
                    </ul>
                    {/*  */}
                </div>
            }
        </>

    );
}
