/**
 * 파일명 : hom0304m.jsx
 * 설명 : GALLERY상세 조회
 * 수정내역
 ************************************************
 * Version    수정일자      수정자    수정내용      
 ************************************************
 * 1.0       2023-03-28   황은택     최초작성
 *           projectController GALLERY 목록 정보 상세 조회 
 */

/************************************************
 * Import 영역  
 ************************************************/
import { useLocation, Link } from "react-router-dom";
import { useState, useEffect } from 'react';
import "../../css/body.css";
import { Button } from 'antd';
// import {HomeOutlined} from '@ant-design/icons';

export default function Hom0304m() {

    const location = useLocation();
    const data = location.state && location.state

    // 회사정보 조회
    const [boardDetailInfo, setboardDetailInfo] = useState("");
    // useEffect(함수, 배열) : 컴포넌트가 화면에 나타났을 때 자동 실행
    useEffect(() => {
        // fetch(url, options) : Http 요청 함수
        fetch("/selectDetailBoard"
           , {
                method: "POST"
              , headers: {'Content-type': 'application/json'}
              , body: JSON.stringify({
                                        id: data.id
                                      , instCd: data.instCd
                                      , boardDcd : data.boardDcd
                                      , boardStCd : data.boardStCd
                                    })
            })
        .then(response => response.json())
        .then(data => {setboardDetailInfo(data)});
    }, []);

    return (
        <div>
            <div className="hom0304m">
                <div className="title">
                    {/* <div>
                        <HomeOutlined /> &gt; 홍보센터 &gt; 갤러리 &gt; <strong>갤러리 상세</strong>
                    </div> */}
                    <h3>&nbsp;GALLERY</h3>
                </div>
                <div className="body">	                
                    <div>
                    <table>
                            <tr>
                                <th>제목</th>
                                <td>{boardDetailInfo.title}</td>
                            </tr>
                            <tr>
                                <th width="10%">글쓴이</th>
                                <td width="30%">{boardDetailInfo.instNm}</td>
                                <th width="10%">등록일</th>
                                <td width="10%">{boardDetailInfo.lstChgDtm}</td>
                            </tr>
                            <tr>
                                <td colspan="4" height="200" valign="top" >
                                    <div style={{whiteSpace: "pre-line"}}>
                                        <br/>{boardDetailInfo.content}<br/>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div style={{paddingTop:"10px"}}>
                        <Button><Link to = "/hom0303m">목록</Link></Button>
                    </div>
                    {/* <div className="next_data_area">
                        <span className="b">다음글 | </span>
                                            다음글이 없습니다.
                    </div>
                    <div className="prev_data_area">
                        <span className="b">이전글 | </span>
                                            이전글이 없습니다.
                    </div> */}
                </div>
            </div>
        </div>
    )
}