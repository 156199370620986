/**
 * 파일명 : hom0401m.jsx
 * 설명 : 채용정보
 * 수정내역
 ************************************************
 * Version    수정일자      수정자    수정내용      
 ************************************************
 * 1.0       2023-03-28   황은택     최초작성 
 * 1.1       2023-10-20   김용재     입사지원서작성, 파일지원접수 open모달 로직 추가
 */

/************************************************
 * Import 영역  
 ************************************************/
// import { useState, useEffect } from 'react';
import "../../css/body.css";  // css
import commonCss from '../../css/Common.module.css';
import screenCss from '../../css/Screen.module.css';
import React, { useState } from 'react';
import Hom0401p from "./hom0401p";
import { Button, ConfigProvider } from "antd";


export default function Hom0401m() {

    // "Mobi" 가 User agent에 포함되어 있으면 모바일
    const isMobile = /Mobi/i.test(window.navigator.userAgent);

    // 모듈 open, cloes  
    const [applcation, setApplication] = useState(false); // 입사지원서작성
    const [file, setFile] = useState(false); // 파일로지원하기

    // 입사지원서작성Open
    const applicationFormOpen = () => {

        // 입사지원서폼이 열려있으면 return;
        if (file == true) {
            return;
        }
        setApplication(true);
    }

    // 파일로지원하기Open
    const AttachFileFormOpen = () => {

        // 파일로첨부하기폼이 열려있으면 return;
        if (applcation == true) {
            return;
        }
        setFile(true);
    }

    // 입사지원서작성Close
    const applicationFormClose = () => {
        setApplication(false);
    }

    // 파일로지원하기Close
    const AttachFileFormClose = () => {
        setFile(false);
    }

    return (
        <div>
            {!isMobile ?
                <div className={screenCss.hom0401m}>
                    <ul className={commonCss.scrn_body}>
                        <li>
                            <dl>
                                <dt>
                                    <h2>채용정보</h2>
                                </dt>
                                <dd>
                                    <img src={require("../../images/together_jobOpening.jpg")} />
                                    <ConfigProvider>
                                        <Button onClick={applicationFormOpen} className={commonCss.btn_action}>
                                            <p>
                                                입사지원서작성
                                            </p>
                                        </Button>
                                    </ConfigProvider>
                                </dd>
                            </dl>
                        </li>
                    </ul>
                </div>
                :
                <div className={screenCss.hom0401m}>
                    <ul className={commonCss.scrn_body}>
                        <li>
                            <dl>
                                <dt>
                                    <h2>채용정보</h2>
                                </dt>
                                <dd>
                                    <img src={require("../../images/together_jobOpening.jpg")} />
                                    <ConfigProvider>
                                        <Button onClick={applicationFormOpen} className={commonCss.btn_action}>
                                            입사지원서작성
                                        </Button>
                                    </ConfigProvider>
                                </dd>
                            </dl>
                        </li>
                    </ul>
                </div>
            }

            {/* 입사지원서 작성 모달 */}
            {applcation ? <Hom0401p isOpen={applcation} onClose={applicationFormClose} /> : null}
        </div >
    )
}