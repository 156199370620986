/**
 * 파일명 : ScrollTop.jsx
 * 설명 : 페이지이동시 스크롤 상단으로 설정
 * 수정내역
 ************************************************
 * Version    수정일자      수정자    수정내용      
 ************************************************
 * 1.0       2023-03-28   황은택     최초작성
 * 
 */

/************************************************
 * Import 영역  
 ************************************************/
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}