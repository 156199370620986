/**
 * 파일명 : hom0103m.jsx
 * 설명 : 사업분야
 * 수정내역
 ************************************************
 * Version    수정일자      수정자    수정내용      
 ************************************************
 * 1.0       2023-03-28   황은택     최초작성
 *     
 */

/************************************************
 * Import 영역  
 ************************************************/
import commonCss from '../../css/Common.module.css';
import screenCss from '../../css/Screen.module.css';

export default function Hom0103m() {

    // "Mobi" 가 User agent에 포함되어 있으면 모바일
    const isMobile = /Mobi/i.test(window.navigator.userAgent);

    return (
        <>
            {!isMobile ?
                // wdb
                <div>
                    <ul className={commonCss.scrn_body}>
                        <li>
                            <dl>
                                <dt>
                                    <h2>사업분야</h2>
                                </dt>
                                <dd>
                                    <img src={require("../../images/together_businessAreas.png")} alt="사업분야"></img>
                                </dd>
                                <dd>
                                    <ul className={commonCss.info_box_01}>
                                        <li>금융업무 및 IT<br />전문인력 보유</li>
                                        <li>다양한 금융기관<br />사업수행 경험</li>
                                        <li>고객과 함께하는<br />사업수행 전략</li>
                                    </ul>
                                </dd>
                            </dl>
                        </li>
                    </ul>
                </div>
                :
                // mobile
                <div>
                    <ul className={commonCss.scrn_body}>
                        <li>
                            <dl>
                                <dt>
                                    <h2>사업분야</h2>
                                </dt>
                                <dd>
                                    <img src={require("../../images/together_businessAreas.png")} alt="사업분야"></img>
                                </dd>
                                <dd>
                                    <ul className={commonCss.info_box_01}>
                                        <li>금융업무 및 IT<br />전문인력 보유</li>
                                        <li>다양한 금융기관<br />사업수행 경험</li>
                                        <li>고객과 함께하는<br />사업수행 전략</li>
                                    </ul>
                                </dd>
                            </dl>
                        </li>
                    </ul>
                </div>
            }
        </>
    );
}