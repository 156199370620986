/**
 * 파일명 : hom0302m.jsx
 * 설명 : 회사소식 상세조회
 * 수정내역
 ************************************************
 * Version    수정일자      수정자    수정내용      
 ************************************************
 * 1.0       2023-03-28   황은택     최초작성
 *           bordController 회사소식 목록 정보 상세 조회 
 *           2024-05-30   김용재     Img클릭 시 Img팝업 Open기능 추가
 */

/************************************************
 * Import 영역  
 ************************************************/
import { useState, useEffect } from 'react';
import { useLocation, Link } from "react-router-dom";
import "../../css/body.css";
import { Button, Image } from 'antd';
import ImgUrl from "../../components/ImgUrl.jsx";


export default function Hom0302m() {

    const location = useLocation();
    const data = location.state && location.state

    const [boardDetailInfo, setboardDetailInfo] = useState("");    // 회사정보 조회

    useEffect(() => {
        fetch("/selectDetailBoard"
           , {
                method: "POST"
              , headers: {'Content-type': 'application/json'}
              , body: JSON.stringify({
                                        id: data.id
                                      , instCd: data.instCd
                                      , boardDcd : data.boardDcd
                                      , boardStCd : data.boardStCd
                                      , sourceDcd : '05'
                                    })
            })
        .then(response => response.json())
        .then(data => {setboardDetailInfo(data)});
        
    }, []);

    // "Mobi" 가 User agent에 포함되어 있으면 모바일
    const isMobile = /Mobi/i.test(window.navigator.userAgent);

    // 2023-11-09 : html태그도 함께 들어있는 content데이터 innerHtml 처리
    const innerTextContent = () => {
        return {__html: boardDetailInfo.content};
    }

    return (
        <div>
            <>
            {!isMobile ? 
                // 웹
                <div className="hom0302m_W">
                    <div className="title">
                        <h3>&nbsp;회사소식</h3>
                    </div>
                    <div className="body">
                        <div>
                            <table>
                                <tbody>
                                    <tr>
                                        <th>제목</th>
                                        <td>{boardDetailInfo.title}</td>
                                    </tr>
                                    <tr>
                                        <th width="10%">글쓴이</th>
                                        <td width="30%">{boardDetailInfo.instNm}</td>
                                        <th width="10%">등록일</th>
                                        <td width="10%">{boardDetailInfo.lstChgDtm}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2" valign="top" style={{borderRightStyle: "none", maxHeight:"400px"}} >
                                            <div style={{whiteSpace: "pre-wrap"}}>
                                                {/* <br/>{boardDetailInfo.content}<br/> */}
                                                {/* 2023-11-09 : html태그도 함께 들어있는 content데이터 innerHtml 처리  */}
                                                <div dangerouslySetInnerHTML={innerTextContent()} />

                                            </div>
                                        </td>                        
                                        <td colSpan="2" >
                                            { boardDetailInfo.content && (<ImgUrl strBase64={boardDetailInfo.fileContent} />)}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div style={{paddingTop:"10px"}}>
                            <Button><Link to = "/hom0301m">목록</Link></Button>
                        </div>
                    </div>
                
                </div>
            :
                // 모바일
                <div className="hom0302m_M">
                    <div className="title">
                        <h3>&nbsp;회사소식</h3>
                    </div>
                    <div className="body">
                        <div>
                            <table>
                                <tr>
                                    <th>제목</th>
                                    <td>{boardDetailInfo.title}</td>
                                </tr>
                                <tr>
                                    <th>글쓴이</th>
                                    <td>{boardDetailInfo.instNm}</td>
                                </tr>
                                <tr>
                                    <th>등록일</th>
                                    <td>{boardDetailInfo.lstChgDtm}</td>
                                </tr>
                                <tr>
                                    <td colSpan="2" >
                                        { boardDetailInfo.content}
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="2" >
                                        { (<ImgUrl strBase64={boardDetailInfo.fileContent} />) }
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="2" height="200" valign="top" style={{borderRightStyle: "none"}} >
                                        <div style={{whiteSpace: "pre-line"}}>
                                            {/* <br/>{boardDetailInfo.content}<br/> */}
                                            {/* 2023-11-09 : html태그도 함께 들어있는 content데이터 innerHtml 처리*/}
                                            <div dangerouslySetInnerHTML={innerTextContent()} />
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div style={{paddingTop:"10px"}}>
                            <Button><Link to = "/hom0301m">목록</Link></Button>
                        </div>
                    </div>
                </div>
            }
            </>
        </div>
    )
}