/**
 * 파일명 : hom0001m.jsx
 * 설명 : 메인화면
 * 수정내역
 ************************************************
 * Version    수정일자      수정자    수정내용      
 ************************************************
 * 1.0       2023-03-28   황은택     최초작성
 *                        메인화면 1
 * 1.1       2024-05-23   기라성    정해진 기간에 합병공고 팝업
 */

/************************************************
 * Import 영역  
 ************************************************/
import { Layout } from 'antd';
import Together_main_1 from "../../images/together_main_1.png";
import Together_main_2 from "../../images/together_main_2.png";
import Together_main_3 from "../../images/together_main_3.png";
import commonCss from "../../css/Common.module.css";
import screenCss from "../../css/Screen.module.css";
import Hom0001p from "./hom0001p";
import {useState } from 'react';


function isNoticed() {
    const startDate = new Date("2024-05-28 00:00:00");
    const endDate = new Date("2024-07-01 00:00:00")
    
    let isNoticed = false;
    if(Date.now() >= startDate && Date.now() <= endDate){
        isNoticed = true
    }
    return isNoticed;
  }

export default function Hom0001m() {
    const [notice, setNotice] = useState(isNoticed); 

    const noticeFormClose = () => {
        setNotice(false);
    }
    return (
        <div className={commonCss.full_srcn}>
            <Layout>
                <ul className={screenCss.hom0001m}>
                    <li>
                        <img src={Together_main_1} />
                    </li>
                    <li>
                        <img src={Together_main_2} />
                    </li>
                    <li>
                        <img src={Together_main_3} />
                    </li>
                </ul>
            </Layout>
            {notice? <Hom0001p isOpen={notice} onClose={noticeFormClose}/> : null}
        </div>
    )
}