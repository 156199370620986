/**
 * 파일명 : hom0005m.jsx
 * 설명 : HEADER MENU
 * 수정내역
 ************************************************
 * Version    수정일자      수정자    수정내용      
 ************************************************
 * 1.0       2023-03-28   황은택     최초작성
 * 1.1       2024-06-21   기라성     투게더홈페이지 들어올시 위드정보 홈페이지로 링크     
 */

/************************************************
 * Import 영역  
 ************************************************/
import { useState } from 'react';
import { Link } from "react-router-dom";
import { Layout, Button } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import "../../css/header.css";
import Accordion from '../../components/Accordion.jsx';

export default function Hom0005m() {
    // 햄버거메뉴 클릭 이벤트
    const hambugar = () => {
      const header = document.querySelector('.header');
      const menu1  = document.querySelector('.header_menu_1');
      const menu2  = document.querySelector('.header_menu_2');
      const accordion = document.querySelector('.accordionHEAD');
      header.classList.toggle('active');
      menu1.classList.toggle('active');
      menu2.classList.toggle('active');
      accordion.classList.toggle('active');
    };
    
    // 헤더로고 클릭 이벤트
    const hambugar_close = () => {
      const header = document.querySelector('.header');
      const menu1  = document.querySelector('.header_menu_1');
      const menu2  = document.querySelector('.header_menu_2');
      const accordion = document.querySelector('.accordionHEAD');
      header.classList.remove('active');
      menu1.classList.remove('active');
      menu2.classList.remove('active');
      accordion.classList.remove('active');
    }
    
    return (
        <Layout>
          <nav className="header">
            <div className="header_logo">
              <Link to="/" onClick={ hambugar_close } className="logo_gradation">
                <img src={require("../../images/together_ci/together_logo_white.png")} style={{height:"28px", float:"center"}} />
              </Link>
            </div>
            <ul className="header_menu_1">
              <li>
                <Link to="/hom0101m" onClick={ hambugar }>
                  회사소개
                </Link>
                <ul className="header_menu_2">
                  <li>
                    <Link to="/hom0101m">
                      회사개요
                    </Link>
                  </li>
                  <li>
                    <Link to="/hom0102m">
                      오시는 길
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/hom0103m" onClick={ hambugar }>
                  사업소개
                </Link>
                <ul className="header_menu_2">
                  <li>
                    <Link to="/hom0103m">
                      사업분야
                    </Link>
                  </li>
                  <li>
                    <Link to="/hom0201m">
                      사업실적
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/hom0401m" onClick={ hambugar }>
                  인재채용
                </Link>
                <ul className="header_menu_2">
                  <li>
                    <Link to="/hom0401m">
                      채용정보
                    </Link>
                  </li>
                  <li>
                    <Link to="/hom0402m">
                      복지제도
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/hom0301m" onClick={ hambugar }>
                  홍보센터
                </Link>
                <ul className="header_menu_2">
                  <li>
                    <Link to="/hom0301m">
                      회사소식
                    </Link>
                  </li>
                  {/* <li>
                    <Link to="/hom0303m">
                      갤러리
                    </Link>
                  </li> */}
                </ul>
              </li>
              <li>
                <Link to="http://togethersol.co.kr:3000/" target="_blank" rel="noopener noreferrer">
                  인트라넷
                </Link>
              </li>
            </ul>

            <div className="accordionHEAD">
              <Accordion summary={"회사소개"}>
                <Link to="/hom0101m" onClick={ hambugar }>
                  회사개요
                </Link>
                <Link to="/hom0102m" onClick={ hambugar }>
                  오시는 길
                </Link>
              </Accordion>
              <Accordion summary={"사업소개"}>
                <Link to="/hom0103m" onClick={ hambugar }>
                  사업분야
                </Link>
                <Link to="/hom0201m" onClick={ hambugar }>
                  사업실적
                </Link>
              </Accordion>
              <Accordion summary={"인재채용"} data={"/hom0401m"}>
                <Link to="/hom0401m" onClick={ hambugar } style={{width:"100%"}}>
                  채용정보
                </Link>
                <Link to="/hom0402m" onClick={ hambugar }>
                  복지제도
                </Link>
              </Accordion>
              <Accordion summary={"홍보센터"}>
                <div>
                  <Link to="/hom0301m" onClick={ hambugar }>
                    회사소식
                  </Link>
                  {/* <br />
                  <Link to="/hom0303m" onClick={ hambugar }>
                    갤러리
                  </Link> */}
                </div>
              </Accordion>
              <Accordion summary={"인트라넷"}>
              </Accordion>
            </div>
            
            <a href="#" className="header_toogleBtn">
              <Button onClick={ hambugar }>
                <MenuOutlined />
              </Button>
            </a>
          </nav>
        </Layout>
    )
}