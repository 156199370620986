/**
 * 파일명 : hom0402m.jsx
 * 설명 : 복지제도
 * 수정내역
 ************************************************
 * Version    수정일자      수정자    수정내용      
 ************************************************
 * 1.0       2023-03-28   황은택     최초작성 
 */

/************************************************
 * Import 영역  
 ************************************************/
import "../../css/body.css";       // css
import commonCss from '../../css/Common.module.css';
import screenCss from '../../css/Screen.module.css';

export default function Hom0402m() {

    // "Mobi" 가 User agent에 포함되어 있으면 모바일
    const isMobile = /Mobi/i.test(window.navigator.userAgent);

    return (
        <>
            {!isMobile ?
                //web
                <div className={screenCss.hom0402m}>
                    <ul className={commonCss.scrn_body}>
                        <li>
                            <dl>
                                <dt>
                                    <h2>복지제도</h2>
                                    <p>
                                        <b>다양한 복지제도 도입</b>을 통해
                                        <br />
                                        즐겁고 행복하게 일할 수 있는 회사를 만들어 나가고자 합니다.
                                    </p>
                                </dt>
                                <dd>
                                    <table className={commonCss.tab_info}>
                                        <thead>
                                            <colgroup>
                                                <col style={{ width: "20%" }} />
                                                <col style={{ width: "40%" }} />
                                            </colgroup>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th>임직원 단체 상해보험 가입</th>
                                                <td>임직원의 상해로 인한 사망·후유 장해 발생시 보험가입금액을 한도로 보험금을 지급합니다.</td>
                                            </tr>
                                            <tr>
                                                <th>퇴직연금제도 운영</th>
                                                <td>근로자의 노후 소득보장과 생활 안정을 위해 확정급여형(DB형) 퇴직연금제도를 운영하고 있습니다.</td>
                                            </tr>
                                            <tr>
                                                <th>법정 4대보험 가입</th>
                                                <td>법정 의무사항인 4대보험(국민연금, 건강보험, 고용보험, 산재보험)에 가입하고 있습니다.</td>
                                            </tr>
                                            <tr>
                                                <th>임직원 건강검진 시행</th>
                                                <td>임직원의 건강관리를 위해 년 1회 건강검진을 시행하고 있습니다.</td>
                                            </tr>
                                            <tr>
                                                <th>경조금 및 경조물품 지원</th>
                                                <td>임직원 본인 및 가족의 결혼, 자녀 출산, 사망 등 경조사 발생시 경조금 및 경조물품을 지급합니다.</td>
                                            </tr>
                                            <tr>
                                                <th>교육 및 연수 지원</th>
                                                <td>금융업무, IT기술 업무능력 향상을 위한 외부 위탁연수를 지원합니다.</td>
                                            </tr>
                                            <tr>
                                                <th>사내 동호회 운영</th>
                                                <td>직원간 친목도모를 위해 사내 동호회 운영 및 활동을 지원합니다.</td>
                                            </tr>
                                            <tr>
                                                <th>연차 휴가</th>
                                                <td>관련법령에 의해 부여된 개인별 연차휴가 범위 내에서 휴가를 사용하실 수 있습니다.</td>
                                            </tr>
                                            <tr>
                                                <th>자기계발 지원</th>
                                                <td>IT관련 자격증 취득 축하금을 지급합니다.</td>
                                            </tr>
                                            <tr>
                                                <th>식대 및 생일 축하금 포인트 지원</th>
                                                <td>식대 및 생일 축하금을 사용할 수 있는 포인트로 지급합니다.</td>
                                            </tr>
                                            <tr>
                                                <th>장기근속자 포상</th>
                                                <td>장기근속 직원들에게 포상금을 지급합니다.</td>
                                            </tr>
                                            <tr>
                                                <th>복지포인트 지급</th>
                                                <td>매년 직급별로 사용할 수 있는 복지포인트를 지급합니다.</td>
                                            </tr>
                                            <tr>
                                                <th>프로젝트수당 지급</th>
                                                <td>프로젝트 종료후 성과에 따른 프로젝트 수당을 지급합니다.</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </dd>
                            </dl>
                        </li>
                    </ul>
                </div>
                :
                //mobile
                <div className={screenCss.hom0402m}>
                    <ul className={commonCss.scrn_body}>
                        <li>
                            <dl>
                                <dt>
                                    <h2>복지제도</h2>
                                </dt>
                                <dd>
                                    <p>
                                        <b>다양한 복지제도 도입</b>을 통해
                                        <br />
                                        즐겁고 행복하게 일할 수 있는 회사를 만들어 나가고자 합니다.
                                    </p>
                                    <table className={commonCss.tab_info}>
                                        <thead>
                                            <colgroup>
                                                <col style={{ width: "20%" }} />
                                                <col style={{ width: "40%" }} />
                                            </colgroup>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th>임직원 단체 상해보험 가입</th>
                                                <td>임직원의 상해로 인한 사망·후유 장해 발생시 보험가입금액을 한도로 보험금을 지급합니다.</td>
                                            </tr>
                                            <tr>
                                                <th>퇴직연금제도 운영</th>
                                                <td>근로자의 노후 소득보장과 생활 안정을 위해 확정급여형(DB형) 퇴직연금제도를 운영하고 있습니다.</td>
                                            </tr>
                                            <tr>
                                                <th>법정 4대보험 가입</th>
                                                <td>법정 의무사항인 4대보험(국민연금, 건강보험, 고용보험, 산재보험)에 가입하고 있습니다.</td>
                                            </tr>
                                            <tr>
                                                <th>임직원 건강검진 시행</th>
                                                <td>임직원의 건강관리를 위해 년 1회 건강검진을 시행하고 있습니다.</td>
                                            </tr>
                                            <tr>
                                                <th>경조금 및 경조물품 지원</th>
                                                <td>임직원 본인 및 가족의 결혼, 자녀 출산, 사망 등 경조사 발생시 경조금 및 경조물품을 지급합니다.</td>
                                            </tr>
                                            <tr>
                                                <th>교육 및 연수 지원</th>
                                                <td>금융업무, IT기술 업무능력 향상을 위한 외부 위탁연수를 지원합니다.</td>
                                            </tr>
                                            <tr>
                                                <th>사내 동호회 운영</th>
                                                <td>직원간 친목도모를 위해 사내 동호회 운영 및 활동을 지원합니다.</td>
                                            </tr>
                                            <tr>
                                                <th>연차 휴가</th>
                                                <td>관련법령에 의해 부여된 개인별 연차휴가 범위 내에서 휴가를 사용하실 수 있습니다.</td>
                                            </tr>
                                            <tr>
                                                <th>자기계발 지원</th>
                                                <td>IT관련 자격증 취득 축하금을 지급합니다.</td>
                                            </tr>
                                            <tr>
                                                <th>식대 및 생일 축하금 포인트 지원</th>
                                                <td>식대 및 생일 축하금을 사용할 수 있는 포인트로 지급합니다.</td>
                                            </tr>
                                            <tr>
                                                <th>장기근속자 포상</th>
                                                <td>장기근속 직원들에게 포상금을 지급합니다.</td>
                                            </tr>
                                            <tr>
                                                <th>복지포인트 지급</th>
                                                <td>매년 직급별로 사용할 수 있는 복지포인트를 지급합니다.</td>
                                            </tr>
                                            <tr>
                                                <th>프로젝트수당 지급</th>
                                                <td>프로젝트 종료후 성과에 따른 프로젝트 수당을 지급합니다.</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </dd>
                            </dl>
                        </li>
                    </ul>
                </div>
            }
        </>
    )
}