/**
 * 파일명 : hom0202m.jsx
 * 설명 : 사업실적 상세
 * 수정내역
 ************************************************
 * Version    수정일자      수정자    수정내용      
 ************************************************
 * 1.0       2023-03-28   황은택     최초작성
 *           projectController 사업실적 목록 정보 상세 조회 
 */

/************************************************
 * Import 영역  
 ************************************************/
import { useState, useEffect } from 'react';
import { useLocation, Link } from "react-router-dom";
import "../../css/body.css";
import { Button } from 'antd';

export default function Hom0202m() {

    const location = useLocation();
    const data = location.state && location.state

    // 회사정보 조회
    const [projectDetailInfo, setprojectDetailInfo] = useState("");
    useEffect(() => {
        fetch("/selectDetailProject"
           , {
                method: "POST"
              , headers: {'Content-type': 'application/json'}
              , body: JSON.stringify({
                                        id: data.id
                                      , instCd: data.instCd
                                      , pjCd : data.pjCd
                                    })
            })
        .then(response => response.json())
        .then(data => {setprojectDetailInfo(data)});
    }, []);

    // "Mobi" 가 User agent에 포함되어 있으면 모바일
    const isMobile = /Mobi/i.test(window.navigator.userAgent);

    return (
        <div>
            <>
            {!isMobile ? 
                // 웹
                <div className="hom0202m_W">
                    <div className="title">
                        <h3>&nbsp;사업실적</h3>
                    </div>
                    <div className="body">
                        <table>
                            <tr>
                                <th>제목</th>
                                <td>{projectDetailInfo.pjNm}</td>
                            </tr>
                            <tr>
                                <th width="10%">글쓴이</th>
                                <td width="30%">{projectDetailInfo.lstChgEmpNm}</td>
                                <th width="10%">등록일</th>
                                <td width="10%">{projectDetailInfo.lstChgDtm}</td>
                            </tr>
                            <tr>
                                <td colspan="4" height="200" valign="top" >
                                    <div style={{whiteSpace: "pre-line"}}>
                                        <br/>{projectDetailInfo.pjCntnt}<br/>
                                    </div>
                                </td>
                            </tr>
                        </table>
                        
                        <div style={{paddingTop:"10px"}}>
                            <Button><Link to = "/hom0201m">목록</Link></Button>
                        </div>
                    </div>
                </div>
            :
                // 모바일
                <div className="hom0202m_M">
                    <div className="title">
                        <h3>&nbsp;사업실적</h3>
                    </div>
                    <div className="body">
                        <table>
                            <tr>
                                <th>제목</th>
                                <td>{projectDetailInfo.pjNm}</td>
                            </tr>
                            <tr>
                                <th>글쓴이</th>
                                <td>{projectDetailInfo.lstChgEmpNm}</td>
                            </tr>
                            <tr>
                                <th>등록일</th>
                                <td>{projectDetailInfo.lstChgDtm}</td>
                            </tr>
                            <tr>
                                <td colspan="4" height="200" valign="top" >
                                    <div style={{whiteSpace: "pre-line"}}>
                                        <br/>{projectDetailInfo.pjCntnt}<br/>
                                    </div>
                                </td>
                            </tr>
                        </table>
                        
                        <div style={{paddingTop:"10px", paddingBottom:"30px"}}>
                            <Button><Link to = "/hom0201m">목록</Link></Button>
                        </div>
                    </div>
                </div>
            }
            </>
        </div>
    )
}