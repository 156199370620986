/**
 * 파일명 : CompanyBoard.jsx
 * 설명 : 회사소식 게시판
 * 수정내역
 ************************************************
 * Version    수정일자      수정자    수정내용      
 ************************************************
 * 1.0       2023-03-28   황은택     최초작성
 * 
 */

/************************************************
 * Import 영역  
 ************************************************/
import { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import "../css/companyBoard.css";
import * as imageUtil from "../utils/imageUtil";

function CompanyBoard({CompanyBoard:{id, instCd, boardDcd, title, content, boardStCd, imgUrl, instNm, lstChgDt, fileContent}, page}) {
    
    const pageId = page;
    const [imageUrl, setImageUrl] = useState(null);
    
    useEffect(() => {
        setImageUrl(imageUtil.fn_getImageUrl(fileContent));
    }, []);

    content = content.replace(/<br\/>/ig, "\n");
    content = content.replace(/<(\/)?([a-zA-Z]*)(\s[a-zA-Z]*=[^>]*)?(\s)*(\/)?>/ig, "");

    // "Mobi" 가 User agent에 포함되어 있으면 모바일
    const isMobile = /Mobi/i.test(window.navigator.userAgent);

    return (
        <div>
            <>
            {!isMobile ? 
                // 웹
                <div className="companyBoard_W">
                    <tr key={id}>
                        <th colspan="2"></th>
                    </tr>
                    <tr>
                        <td className="companyBoardMain">
                            <td className="boardLeft">
                                <div className="boardThumb">
                                    <Link to = {pageId}
                                            state = {{
                                                        id : id
                                                        , instCd : instCd
                                                        , boardDcd : boardDcd
                                                        , boardStCd : boardStCd
                                                    }}>
                                        <img src={imageUrl} />
                                    </Link>
                                </div>					
                            </td>
                            <td className="boardRight">
                                <div className="boardCon">
                                    <div className="boardRightTitle">
                                        <Link to = {pageId}
                                                    state = {{
                                                            id : id
                                                            , instCd : instCd
                                                            , boardDcd : boardDcd
                                                            , boardStCd : boardStCd
                                                            }}>
                                                   {(title)}
                                        </Link>
                                    </div>
                                    <div className="boardRightContents">
                                        <Link to = {pageId}
                                                state = {{
                                                        id : id
                                                        , instCd : instCd
                                                        , boardDcd : boardDcd
                                                        , boardStCd : boardStCd
                                                        }}>
                                                   {content}
                                        </Link>
                                    </div>
                                    <div className="boardRightWriter">
                                        {instNm}<font>&nbsp;&nbsp;|&nbsp;&nbsp;</font>
                                        {lstChgDt} 
                                    </div>
                                </div>
                            </td>
                        </td>
                    </tr>
                </div>
            :
                // 모바일
                <div className="companyBoard_M">
                    <tr key={id}>
                        <th colspan="2"></th>
                    </tr>
                    <tr>
                        <td className="companyBoardMain">
                            <tr>
                                <td className="boardLeft">
                                    <div className="boardThumb">
                                        <Link to = {pageId}
                                                state = {{
                                                            id : id
                                                            , instCd : instCd
                                                            , boardDcd : boardDcd
                                                            , boardStCd : boardStCd
                                                        }}>
                                            <img src={imageUrl} />
                                        </Link>
                                    </div>					
                                </td>
                            </tr>
                            <tr>
                                <td className="boardRight">
                                    <div className="boardCon">
                                        <div className="boardRightTitle">
                                            <Link to = {pageId}
                                                        state = {{
                                                                id : id
                                                                , instCd : instCd
                                                                , boardDcd : boardDcd
                                                                , boardStCd : boardStCd
                                                                }}>
                                                       {(title)}
                                            </Link>
                                        </div>
                                        <div className="boardRightContents">
                                            <Link to = {pageId}
                                                    state = {{
                                                            id : id
                                                            , instCd : instCd
                                                            , boardDcd : boardDcd
                                                            , boardStCd : boardStCd
                                                            }}>
                                                       {content}
                                            </Link>
                                        </div>
                                        <div className="boardRightWriter">
                                            {instNm}<font>&nbsp;&nbsp;|&nbsp;&nbsp;</font>
                                            {lstChgDt} 
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </td>
                    </tr>
                </div>
            }
            </>
        </div>
    )
}
export default CompanyBoard;
