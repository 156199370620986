/**
 * 파일명 : TgCombo.jsx
 * 설명 : 공통콤보박스
 * 수정내역
 ************************************************
 * Version    수정일자      수정자    수정내용      
 ************************************************
 * 1.0       2023-11-22    김용재     최초작성
 * 
 */

/************************************************
 * Import 영역  
 ************************************************/
import { Select } from "antd";
import {useEffect, useState} from "react";
import "../css/modal.css"; // css

/**
 * TgCombo : 공통 콤보박스 컴포넌트
 * @param {Object} props         - 부모 컴포넌트로부터 전달되는 속성
 *   @property {string}   id         - 컴포넌트의 ID
 *   @property {string}   name       - 컴포넌트의 이름
 *   @property {string}   className  - 컴포넌트의 CSS 클래스
 *   @property {function} onChange   - 콤보박스 값이 변경될 때 호출되는 함수
 *   @property {string}   indexKey   - list 상태인 경우 배열의 인덱스 키(list.map 사용시 사용)
 *   @property {object}   params     - 콤보박스에 필요한 Json 옵션
 *     @property {string}   params.cd_grp_id     - 코드 그룹 ID
 *     @property {string}   params.data_node     - 선택된 코드 값이 저장될 state 필드명
 *     @property {string}   params.data_CodeNm   - 선택된 코드명이 저장될 state 필드명
 *     @property {function} params.useState      - 상태를 변경하는 함수
 *     @property {string}   params.listYn        - Y 또는 N, useState가 list인 경우(list.map 사용시 사용)
 *     @property {string}   params.placeholder   - 콤보박스의 플레이스홀더
 *     @property {string}   params.all_cd        - 전체 코드
 *     @property {string}   params.all_nm        - 전체 코드명
 *     @property {string}   params.default_value - 콤보박스의 기본값
 *     @property {int}      params.maxHeight     - 드롭다운의 최대 높이
 *     @property {array}    params.directCode    - 코드명을 직접 추가하는 배열 ex) [{cd:'code', cdNm:'name'}, {cd:'code', cdNm:'name'}, {cd:'code', cdNm:'name'}]
 */
const TgCombo = (props) => {

    const { id, name, className, params, onChange, indexKey } = props;   
    const { Option } = Select;

    /************************************************
     * params 변수 선언 영역
     ************************************************/
    let strCdGrpId      = '';
    let strPlaceHolder  = '';
    let strDefaultValue = '';
    let intMaxHeight    = '';
    let arrDirectCode   = '';

    if( params !== undefined ){
        strCdGrpId      = params.cd_grp_id; 
        strPlaceHolder  = params.placeholder;
        strDefaultValue = params.default_value;
        intMaxHeight    = params.maxHeight;
        arrDirectCode   = params.directCode;
    }

    const [codeList, setCodeList] = useState([]);   
    
    /************************************************
     * 화면 Load시 수행
     ************************************************/
    useEffect(() => {
        
        if(arrDirectCode !== undefined && arrDirectCode !== ''){

            let copyCodeList = [...params.directCode];

            // 전체코드 key가 있으면 [전체] 추가
            if(params.all_cd !== undefined){
                copyCodeList = addAllCode(copyCodeList);
            }
            
            setCodeList(copyCodeList);

        } else {
            // 코드 조회
            selectCodeList();
        }        
    }, []);

    // 공통코드 조회
    const selectCodeList = () => {

        fetch('/selectCodeList', {
            method:'POST',
            headers: {'Content-type': 'application/json'},
            body: JSON.stringify({
                cdGrpId: strCdGrpId,
            })
        })
        .then((response) => response.json())
        .then((data) => {

            let copyCodeList = [...data.data.codeList];

            // 전체코드 key가 있으면 [전체] 추가
            if(params.all_cd !== undefined){
                copyCodeList = addAllCode(copyCodeList);
            }
            
            setCodeList(copyCodeList);
        })
    }

    // [전체]코드 추가
    const addAllCode = (codeList) => {

        let copyCodeList = [...codeList];

        const allCode = {
            cd: params.all_cd,
            cdNm: params.all_nm,
        };

        copyCodeList = [allCode, ...copyCodeList];
        return copyCodeList;
    }
        
    // onChange 이벤트 
    const onChangeHandel = (_, data) => {
        
        const codeValue = data.value;
        const codeName  = data.children;        
       
        // state가 list 형태이면 배열 index에 값 셋팅
        if(params.listYn === 'Y'){            
            
            params.useState((prevState) => prevState.map((field, i) => i=== indexKey ? {...field, [params.data_node] : codeValue} : field));

        } else {
           
            params.useState((prevState) => ({
                ...prevState,
                [params.data_node] : codeValue,
                [params.data_CodeNm] : codeName,
            }));           
        }
                                 
        // 부모 onChange 이벤트 props가 있으면 onChange 이벤트 실행
        if(onChange){            
            onChange(data, params, indexKey);
        }
    };

    // SelectBox Dropdown 최대 높이 지정 높이 초과하면 스크롤
    const renderDropdown = (combo) => {
        return (
            <div style={{ maxHeight: intMaxHeight, overflow: 'auto'}}>
                {combo}
            </div>
        )
    }

    return(
        <Select 
            id={id}
            name={name}
            className={className}                      
            placeholder={strPlaceHolder}
            onChange={onChangeHandel}
            defaultValue={strDefaultValue}
            dropdownRender={renderDropdown}
        >
            {
                codeList.map((code) => (
                <Option key={code.cd} value={code.cd}>
                    {code.cdNm}
                </Option>
                ))
            }
        </Select>
    );   
}
export default TgCombo;