/**
 * 파일명 : hom0102m.jsx
 * 설명 : 오시는 길
 * 수정내역
 ************************************************
 * Version    수정일자      수정자    수정내용      
 ************************************************
 * 1.0       2023-03-28   황은택     최초작성
 *           businessController 호출 - 회사 정보 조회
 */

/************************************************
 * Import 영역  
 ************************************************/
import { useState, useEffect } from 'react';
import "../../css/body.css";  // css
import KAKAOMap from "../../components/MapKakao.jsx";  // KAKAO MAP

export default function Hom0102m() {

    // 회사정보 조회
    const [companyInfo, setCompanyInfo] = useState("");
    useEffect(() => {
        fetch("/selectCompany")
        .then((response) => response.json())
        .then(data => {setCompanyInfo(data)});
    }, []);

    // "Mobi" 가 User agent에 포함되어 있으면 모바일
    const isMobile = /Mobi/i.test(window.navigator.userAgent);

    return (
        <div>
            <>
            {!isMobile ? 
                // 웹
                <div className="hom0102m_W">
                    <div className="title">
                        <h3>&nbsp;오시는 길</h3>
                    </div>
                    <div className="body">
                        <div className="txt">
                            <p><b>선유도역 2번출구</b> 300M이내</p>
                            <ul>
                                <li><span>Address</span>{companyInfo.address}</li>
                                <li><span>Tel</span>{companyInfo.poneNumber}</li>
                                <li><span>Fax</span>{companyInfo.faxNumber}</li>
                            </ul>
                        </div>
                        <div className="kakaoMap">
                            <KAKAOMap />
                        </div>
                    </div>
                </div>
            :
                // 모바일
                <div className="hom0102m_M">
                    <div className="title">
                        <h3>&nbsp;오시는 길</h3>
                    </div>
                    <div className="body">
                        <div className="txt">
                            <p><b>선유도역 2번출구</b> 300M이내</p>
                            <ul>
                                <li><span>Address</span><br/>{companyInfo.address}</li>
                                <li><span>Tel</span>{companyInfo.poneNumber}</li>
                                <li><span>Fax</span>{companyInfo.faxNumber}</li>
                            </ul>
                        </div>
                        <div className="kakaoMap">
                            <KAKAOMap />
                        </div>
                    </div>
                </div>
            }
            </>
        </div>
    )
}